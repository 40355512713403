import React from "react"
import Layout from '@components/layout/layout.jsx'
import DocumentHeadMeta from '@components/utility/SEO.jsx'

import './styles.scss'
//Components
import UnsubscribeForm from '@components/form/Form.Unsubscribe.jsx'
// markup
const UnsubscribePage = ({ data }) => {

  return (
    <Layout addedClass="page--unsubscribe">
      <DocumentHeadMeta title='Unsubscribe | ALL IN&reg; for Lupus Nephritis' description="We're sorry to see you go. Unsubscribe here if you would no longer like to receive updates and information from ALL IN&reg; for Lupus Nephritis." canonicalURL='https://www.allinforlupusnephritis.com/unsubscribe' />
      <UnsubscribeForm />
    </Layout>
  )
}

export default UnsubscribePage

